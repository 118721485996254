import React from "react"
import { Layout } from "../layouts/Layout"
import SEO from "../components/seo"

const Policy = () => {
    return (
        <Layout ModalTitle={""}>

            <SEO title="Home" />
            <div>
                <h2>Πολιτική απορίτου</h2>

                <p>Η διαχείριση και προστασία των προσωπικών δεδομένων του επισκέπτη των υπηρεσιών του site (artiapix.gr) υπόκειται στους όρους του παρόντος κειμένου καθώς και από τις σχετικές διατάξεις του ελληνικού (Ν. 2472/1997 για την προστασία του ατόμου από την προστασία δεδομένων προσωπικού χαρακτήρα όπως έχει συμπληρωθεί με τις αποφάσεις του Προέδρου της Επιτροπής Προστασίας Προσωπικών Δεδομένων, τα Π. Δ. 207/1998 και 79/2000 και το άρθρο 8 του Ν. 2819/2000) και του ευρωπαϊκού δικαίου (οδηγίες 95/46/ΕΚ και 97/66/ΕΚ).</p>

                <p>Οι παρόντες όροι διατυπώνονται λαμβανομένων υπόψη τόσο της ραγδαίας ανάπτυξης της τεχνολογίας και ειδικότερα του διαδικτύου όσο και της υπάρχουσας νομοθεσίας σχετικά με τα ζητήματα αυτά. Σε κάθε περίπτωση η ιστοσελίδα διατηρεί το δικαίωμα αλλαγής των όρων προστασίας των προσωπικών δεδομένων κατόπιν ενημέρωσης των επισκεπτών και πάντα μέσα στο υπάρχον ή και ενδεχόμενο ελληνικό κι ευρωπαϊκό νομικό πλαίσιο για τα ζητήματα αυτά.<br />

Εάν κάποιος επισκέπτης δε συμφωνεί με τους όρους προστασίας των προσωπικών δεδομένων που προβλέπονται στο παρόν κείμενο οφείλει να μη χρησιμοποιεί τις υπηρεσίες της ιστοσελίδας.<br />
Τα προσωπικά στοιχεία τα οποία συλλέγει η ιστοσελίδα είναι τα ακόλουθα:<br />
Cookies<br />
&nbsp; Η ιστοσελίδα ενδέχεται να χρησιμοποιεί cookies για την αναγνώριση του επισκέπτη σε ορισμένες υπηρεσίες και σελίδες του. Τα cookies είναι μικρά αρχεία κειμένου που αποθηκεύονται στο σκληρό δίσκο κάθε επισκέπτη και δεν λαμβάνουν γνώση οποιουδήποτε εγγράφου ή αρχείου από τον υπολογιστή του.</p>

                <p>Χρησιμοποιούνται για την διευκόλυνση πρόσβασης του επισκέπτη όσον αφορά τη χρησιμοποίηση συγκεκριμένων υπηρεσιών της ιστοσελίδας, για στατιστικούς λόγους προκειμένου να καθορίζονται οι περιοχές στις οποίες οι υπηρεσίες της ιστοσελίδας είναι χρήσιμες ή δημοφιλείς ή για λόγους διαφημιστικούς.</p>

                <p>Ο επισκέπτης της ιστοσελίδας μπορεί να ρυθμίσει τον υπολογιστή του κατά τέτοιο τρόπο ώστε είτε να τον προειδοποιεί για τη χρήση των cookies σε συγκεκριμένες υπηρεσίες της σελίδας είτε να μην επιτρέπει την αποδοχή της χρήσης cookies σε καμία περίπτωση. Σε περίπτωση που ο επισκέπτης των συγκεκριμένων υπηρεσιών και σελίδων της ιστοσελίδας δεν επιθυμεί την χρήση cookies για την αναγνώριση του δεν μπορεί να έχει περαιτέρω πρόσβαση στις υπηρεσίες αυτές.</p>

                <p>Links to other sites ("Δεσμοί με άλλες ιστοσελίδες")<br />
Η ιστοσελίδας μας περιλαμβάνει links ("δεσμούς") προς άλλα web sites τα οποία και δεν ελέγχονται από το ίδιο αλλά από τους ιδιοκτήτες τους (φυσικά ή νομικά πρόσωπα) . Σε καμία περίπτωση δεν ευθύνεται το site του για τους Όρους Προστασίας των Προσωπικών Δεδομένων τους οποίους αυτοί ακολουθούν ούτε και δηλώνει πως συμφωνεί κατά ποσοστό 100% με τις θέσεις, τις απόψεις και το περιεχόμενο των άλλων ιστοσελίδων.</p>

                <p>IP Addresses<br />
H διεύθυνση IP μέσω της οποίας ο Η/Υ έχει πρόσβαση στο Internet και στη συνέχεια στην ιστοσελίδας μας καταγράφεται και αξιοποιείται αποκλειστικά για την συγκέντρωση στατιστικών στοιχείων που αφορούν στην παρακολούθηση της επισκεψιμότητας.</p>

                <p>Γενικοί όροι προστασίας των προσωπικών δεδομένων<br />
Η ιστοσελίδα artiapix.gr διαφυλάσσει τον προσωπικό χαρακτήρα των στοιχείων σας και δε δύναται να τα μεταβιβάσει σε οποιονδήποτε τρίτο (φυσικό ή νομικό πρόσωπο) για κανένα λόγο με την εξαίρεση σχετικών διατάξεων του νόμου και προς τις αρμόδιες και μόνο αρχές.<br />
Επισκέπτες που είναι ανήλικοι έχουν πρόσβαση στις υπηρεσίες μόνο με τη συγκατάθεση των γονέων ή των κηδεμόνων τους και δεν έχουν υποχρέωση να υποβάλλουν τα προσωπικά τους στοιχεία. Σε περίπτωση υποβολής τέτοιων στοιχείων από ανήλικους κι εφόσον το γεγονός γνωστοποιηθεί προς αυτό, το site του διαγράφει όλες τις σχετικές πληροφορίες.</p>

                <p>Σύμβαση χρήσης<br />
Ο επισκέπτης των σελίδων και των υπηρεσιών παραχωρεί τη συγκατάθεσή του στους κατωτέρω όρους χρήσης, που ισχύουν για το σύνολο του περιεχομένου, των σελίδων, των γραφικών, των εικόνων, των φωτογραφιών και των αρχείων που περιλαμβάνονται στο site συνεπώς, οφείλει να διαβάσει προσεκτικά τους όρους αυτούς πριν από την επίσκεψη ή τη χρήση των σελίδων και των υπηρεσιών του site.<br />
Εάν δε συμφωνεί, τότε οφείλει να μην κάνει χρήση των υπηρεσιών και του περιεχομένου της σελίδας. Ο επισκέπτης παρακαλείται να ελέγχει το περιεχόμενο των συγκεκριμένων σελίδων για ενδεχόμενες αλλαγές. Η εξακολούθηση της χρήσης ακόμη και μετά τις όποιες αλλαγές σημαίνει την ανεπιφύλακτη εκ μέρους του επισκέπτη αποδοχή των όρων αυτών.</p>

                <p>Δικαιώματα πνευματικής ιδιοκτησίας<br />
Εκτός των ρητά αναφερόμενων εξαιρέσεων (πνευματικά δικαιώματα τρίτων, συνεργατών και φορέων), όλο το περιεχόμενο της ιστοσελίδας, συμπεριλαμβανομένων εικόνων, γραφικών, φωτογραφιών, σχεδίων, κειμένων, των παρεχομένων υπηρεσιών και γενικά όλων των αρχείων αυτού του site, αποτελούν πνευματική ιδιοκτησία, της ιστοσελίδας και προστατεύονται κατά τις σχετικές διατάξεις του ελληνικού δικαίου, του ευρωπαϊκού δικαίου και των διεθνών συμβάσεων.<br />
Συνεπώς, κανένα εξ αυτών δε δύναται να αποτελέσει εν όλω ή εν μέρει αντικείμενο πώλησης, αντιγραφής, τροποποίησης, αναπαραγωγής, αναδημοσίευσης ή να "φορτωθεί", να μεταδοθεί ή να διανεμηθεί με οποιονδήποτε τρόπο με σκοπό το προσωπικό κέρδος.<br />
Εξαιρείται η περίπτωση της μεμονωμένης αποθήκευσης ενός και μόνου αντιγράφου τμήματος του περιεχομένου σε έναν απλό προσωπικό Η/Υ (ηλεκτρονικό υπολογιστή) μη εμπορική χρήση και χωρίς απαλοιφή της ένδειξης προέλευσής τους από την ιστοσελίδα artiapix.gr, χωρίς να θίγονται με κανένα τρόπο τα σχετικά δικαιώματα πνευματικής ιδιοκτησίας.<br />
Για τους παραπάνω λόγους το site δύναται να απαγορέψει την πρόσβαση στις πληροφορίες που παρουσιάζει σε οποιονδήποτε ο οποίος δεν συμμορφώνεται με τα παραπάνω.<br />
Τα λοιπά προϊόντα ή υπηρεσίες που αναφέρονται στις ηλεκτρονικές σελίδες του παρόντος κόμβου και φέρουν τα σήματα των αντίστοιχων οργανισμών, εταιρειών, συνεργατών φορέων, ενώσεων ή εκδόσεων, αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία και συνεπώς οι φορείς αυτοί φέρουν τη σχετική ευθύνη.</p>

                <p>Ευθύνη επισκέπτη<br />
Ο επισκέπτης των σελίδων και των υπηρεσιών αναλαμβάνει την ευθύνη για οποιαδήποτε ζημία προκαλείται από κακή ή αθέμιτη χρήση των σχετικών υπηρεσιών.</p>

                <p>Για οποιαδήποτε ζημιά ή ηθική βλάβη προκληθεί δια των υπηρεσιών μας, από την ανάρτηση, δημοσίευση ή κοινοποίηση οιουδήποτε περιεχομένου, υπεύθυνος για την αποκατάσταση της είναι αποκλειστικά ο επισκέπτης – μέλος.</p>

                <p>Αν παρά τον όγκο των στοιχείων, πληροφοριών, περιεχομένων κλπ., ο δικτυακός μας τόπος, πληροφορηθεί με οποιονδήποτε τρόπο, περιεχόμενο που προσβάλλει την προσωπικότητα την τιμή ή την υπόληψη οιουδήποτε τρίτου, και κατά την κρίση του επιφέρει ηθική βλάβη και περιουσιακή ζημία σε τρίτον, δικαιούται χωρίς προειδοποίηση,</p>

                <p>α) να διαγράψει άμεσα το συγκεκριμμένο περιεχόμενο.</p>

                <p>β) Να διακόψει τη λειτουργία του λογαριασμού του χρήστη- μέλους.</p>

                <p>γ) Να λάβει οποιοδήποτε κατά τη κρίση του νομικό μέτρο, για την αποτροπή της συγκεκριμμένης πράξης, και της πρόκλησης οιασδήποτε ζημιάς σε τρίτους.</p>

                <p>Ενδεικτική παράθεση απαγορευμένων χρήσεων των υπηρεσιών της ιστοσελίδας artiapix.gr</p>

                <p>Οι χρήστες, τηρώντας τη κείμενη νομοθεσία, το Σύνταγμα, τις Διεθνείς Συμβάσεις, και τις γενικά παραδεκτές αρχές δεοντολογίας, οφείλουν να απέχουν από:</p>

                <p>1) την ανάρτηση και δημοσίευση, κάθε περιεχομένου, που συγκροτεί αντικειμενικά προσβολή της προσωπικότητας, της τιμής, της υπόληψης, της δημόσιας εικόνας, της περιουσίας οιουδήποτε τρίτου προσώπου.</p>

                <p>2) Την ανάρτηση και δημοσίευση περιεχομένου, που είναι αντίθετο με το Νόμο, τα ήθη, είναι δυσφημιστικό ή αντιτίθεται στις Συνταγματικές έννοιες προστασίας της ζωής, της τιμής, της περιουσίας, της αξίας του ανθρώπου ή παραβιάζει τα προσωπικά δεδομένα άλλων, ή συγκροτεί την υποκειμενική ή αντικειμενική υπόσταση οιουδήποτε εγκλήματος ή χαρακτηρίζεται από ρατσιστική διάθεση.</p>

                <p>3) Την ανάρτηση και δημοσίευση περιεχομένου, με πορνογραφικό υλικό γενικά, ή παιδική πορνογραφία ειδικά, ή περιεχομένου που προσβάλλει την από το Σύνταγμα προβλεπόμενη προστασία της ανηλικότητας.</p>

                <p>4) Την ανάρτηση και δημοσίευση περιεχομένου, που προσβάλλει το Δημοκρατικό Πολίτευμα, το δικαίωμα ανεξιθρησκείας την προστασία των μειονοτικών ομάδων, προωθεί το ρατσισμό και τη ξενοφοβία ή προσβάλλει τα ατομικά πολιτικά και κοινωνικά δικαιώματα των Ελλήνων πολιτών.</p>

                <p>Κάθε χρήστης – μέλος των σελίδων του δικτυακού μας τόπου, ευθύς ως αντιληφθεί κάποια από τις παραπάνω απαγορευμένες χρήσεις, οφείλει, να ειδοποιεί τον δικτυακό μας τόπο, ο οποίος πλέον υποχρεούται, να διαγράφει άμεσα το οποιοδήποτε σχετικό περιεχόμενο, και να διακόπτει τη λειτουργία του λογαριασμού του χρήστη – μέλους. Ο μεγάλος όγκος των επισκεπτών – χρηστών των σελίδων, και ο πολύ μεγάλος όγκος των θεμάτων που αναρτώνται και δημοσιεύονται, καθιστά αναγκαία τη συμβολή όλων μας, στον εντοπισμό κάθε μη νόμιμης χρήσης, και στον αποκλεισμό τέτοιων φαινομένων.</p>

                <p>Ο επισκέπτης – χρήστης – μέλος των σελίδων του site, εκτός της αποκλειστικής του ευθύνης για την ανάρτηση και δημοσίευση οιουδήποτε θέματος, αναλαμβάνει την υποχρέωση, σε τυχόν νομική εμπλοκή, να αποκαταστήσει οποιαδήποτε ζημιά του δικτυακού μας τόπου, ή οιουδήποτε τρίτου, και πιο συγκεκριμμένα σε περίπτωση ζημιάς τρίτου και έναρξης νομικής διαδικασίας ενώπιον των Δικαστηρίων, υποχρεούται να ασκεί πρόσθετη παρέμβαση υπέρ του δικτυακού μας τόπου, και στη συνέχεια να καταβάλει ενδεχομένως οποιοδήποτε ποσό , εκ του λόγου αυτού επιδικαστεί υπέρ τρίτου, και σε βάρος του δικτυακού μας τόπου. Σε κάθε περίπτωση ο χρήσης – μέλος του δικτυακού μας τόπου αποδέχεται ως νόμιμο και δίκαιο το δικαίωμα μας, να διακόπτουμε την χρήση, των προσωπικών κωδικών πρόσβασής (password) αν ο χρήστης – μέλος παραβιάζει τους παραπάνω ενδεικτικά καταγραφόμενους όρους.</p>

                <p>Περιορισμός ευθύνης site artiapix.gr<br />
Υπό οποιεσδήποτε συνθήκες, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, το site&nbsp; δεν ευθύνεται για οποιασδήποτε μορφής ζημία υποστεί ο επισκέπτης των σελίδων, υπηρεσιών, επιλογών και περιεχομένων του site στις οποίες προβαίνει με δική του πρωτοβουλία και με τη γνώση των όρων του παρόντος.</p>

                <p>Τα περιεχόμενα του site παρέχονται "όπως ακριβώς είναι" χωρίς καμία εγγύηση εκπεφρασμένη ή και συνεπαγόμενη με οποιοδήποτε τρόπο. Στο μέγιστο βαθμό και σύμφωνα με το νόμο, το site αρνείται όλες τις εγγυήσεις εκπεφρασμένες ή και συνεπαγόμενες, συμπεριλαμβανομένων, όχι όμως περιοριζόμενων σε αυτό, αυτών, οι οποίες συνεπάγονται την εμπορευσιμότητα και την καταλληλότητα για ένα συγκεκριμένο σκοπό.</p>

                <p>Το site δεν εγγυάται ότι οι σελίδες, οι υπηρεσίες, οι επιλογές και τα περιεχόμενα θα παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα διορθώνονται.</p>

                <p>Επίσης το site δεν εγγυάται ότι το ίδιο ή οποιοδήποτε άλλο συγγενικό site ή οι εξυπηρετητές "servers" μέσω των οποίων αυτά τίθενται στη διάθεσή σας, σας παρέχονται χωρίς "υιούς" ή άλλα επιζήμια συστατικά.</p>

                <p>Το site δεν εγγυάται σε καμία περίπτωση την ορθότητα, την πληρότητα ή και διαθεσιμότητα των περιεχομένων, σελίδων, υπηρεσιών, επιλογών ή τα αποτελέσματά τους.<br />
Το κόστος των ενδεχόμενων διορθώσεων ή εξυπηρετήσεων, το αναλαμβάνει ο επισκέπτης και σε καμία περίπτωση το site.</p>

                <p>"Δεσμοί" (links) προς άλλα sites<br />
Το site δεν ελέγχει τη διαθεσιμότητα, το περιεχόμενο, την πολιτική προστασίας των προσωπικών δεδομένων, την ποιότητα και την πληρότητα των υπηρεσιών άλλων web sites και σελίδων στα οποία παραπέμπει μέσω "δεσμών", hyperlinks ή διαφημιστικών banners.<br />
Συνεπώς για οποιοδήποτε πρόβλημα παρουσιασθεί κατά την επίσκεψη τους οφείλετε να απευθύνεστε απευθείας στα αντίστοιχα web sites και σελίδες, τα οποία και φέρουν τη σχετική ευθύνη για την παροχή των υπηρεσιών τους.<br />
Το site σε καμία περίπτωση δεν πρέπει να θεωρηθεί ότι ενστερνίζεται ή αποδέχεται το περιεχόμενο ή τις υπηρεσίες των web sites και των σελίδων στα οποία παραπέμπει ή ότι συνδέεται με αυτά κατά οποιονδήποτε άλλο τρόπο.</p>
            </div>
        </Layout>
    )
}

export default Policy
